import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import * as actionTypes from 'store/actions';

// assets
import logo from 'assets/images/logo.jpg';
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import useAuth from 'hooks/useAuth';
import { getPictureUploadUrl } from 'utils/helperFunction';
import useTenant from 'hooks/useTenant';
import api from 'api';

const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        minWidth: '200px',
        maxWidth: '280px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px'
        }
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        color: theme.palette.primary.dark,
        transition: 'all .2s ease-in-out',
        '&[aria-controls="menu-list-grow"],&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
        }
    },
    box: {
        marginLeft: '16px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '8px'
        }
    }
}));

// ===========================|| LOCALIZATION ||=========================== //

const TenantSection = () => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { getTenant, setTenant } = useTenant();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [tenant, setCurrentTenant] = React.useState(getTenant());
    const [tenants, setTenants] = React.useState([]);

    const handleListItemClick = (event, index) => {
        setCurrentTenant(index);
        setTenant(index);
        // dispatch({ type: actionTypes.THEME_LOCALE, locale: index });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    React.useEffect(() => {
        async function getTenants() {
            const res = await api.find('user/getTenants')
            if (res && res.items.length) {
                setTenants(res.items);   
            }
        }

        getTenants();
    }, [tenant]);

    return (
        <>
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="square"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                    <img 
                        src={ tenant && tenant.logoPicture && tenant.logoPicture.fileName ? 
                            getPictureUploadUrl() + 'media/' + tenant.logoPicture.fileName : logo } 
                        width={25}
                        stroke={1.5} size="1.3rem" alt='' />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top" in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav" className={classes.navContainer}>
                                {
                                    tenants.map((t, i) => (
                                        <ListItemButton selected={t._id === tenant._id} onClick={(event) => handleListItemClick(event, t)}>
                                            <ListItemIcon>
                                            <img 
                                                src={ t.logoPicture && t.logoPicture.fileName ? 
                                                    getPictureUploadUrl() + 'media/' + t.logoPicture.fileName : logo } 
                                                width={25}
                                                stroke={1.5} size="1.3rem" alt='' />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">{t.name}</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                    ))
                                }
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default TenantSection;
