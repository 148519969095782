import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconDashboard, IconDeviceAnalytics, IconReportAnalytics, IconChartInfographic, IconMenu } from '@tabler/icons';

// constant
const icons = { IconMenu, IconDashboard, IconDeviceAnalytics, IconReportAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/dashboard',
            icon: IconHome,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            url: '/reports',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: <FormattedMessage id="analytics" />,
            type: 'item',
            url: '/analytics',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'models',
            title: <FormattedMessage id="models" />,
            type: 'collapse',
            icon: icons.IconMenu,
            children: [
                {
                    id: 'keywords',
                    title: <FormattedMessage id="keywords" />,
                    type: 'item',
                    url: '/keywords',
                    breadcrumbs: false
                },
                {
                    id: 'vips',
                    title: <FormattedMessage id="vips" />,
                    type: 'item',
                    url: '/page/vip/list',
                    breadcrumbs: false
                },
                {
                    id: 'persons',
                    title: <FormattedMessage id="persons" />,
                    type: 'item',
                    url: '/page/person/list',
                    breadcrumbs: false
                },
                {
                    id: 'newsTypes',
                    title: <FormattedMessage id="newsTypes" />,
                    type: 'item',
                    url: '/page/newsType/list',
                    breadcrumbs: false
                },
                {
                    id: 'contentTypes',
                    title: <FormattedMessage id="contentTypes" />,
                    type: 'item',
                    url: '/page/contentType/list',
                    breadcrumbs: false
                },
                {
                    id: 'contentSubjects',
                    title: <FormattedMessage id="contentSubjects" />,
                    type: 'item',
                    url: '/page/contentSubject/list',
                    breadcrumbs: false
                },
                {
                    id: 'tags',
                    title: <FormattedMessage id="tags" />,
                    type: 'item',
                    url: '/page/tag/list',
                    breadcrumbs: false
                },
                {
                    id: 'politicalParties',
                    title: <FormattedMessage id="politicalParties" />,
                    type: 'item',
                    url: '/page/politicalParty/list',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default dashboard;
