import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconWorldWww, IconNotebook } from '@tabler/icons';

// ===========================|| NEWS WEBSITES PAGE & NEWS ARTICLE ITEMS ||=========================== //

const other = {
    id: 'news_websites-roadmap',
    type: 'group',
    children: [
        {
            id: 'news_websites',
            title: <FormattedMessage id="news_websites" />,
            type: 'item',
            url: '/news_websites',
            icon: IconWorldWww,
            breadcrumbs: false
        },
        {
            id: 'news_articles',
            title: <FormattedMessage id="news_articles" />,
            type: 'item',
            url: '/news_articles',
            icon: IconNotebook,
            breadcrumbs: false
        }
    ],
};

export default other;
