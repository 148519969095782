import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// news websites page routing
const AdminViewPage = Loadable(lazy(() => import('pages/admin/viewPage')));
const AdminListPage = Loadable(lazy(() => import('pages/admin/listPage')));
const AdminCrudPage = Loadable(lazy(() => import('pages/admin/crudPage')));
const ListPage = Loadable(lazy(() => import('pages/admin/render/pageRender.list')));

// ===========================|| MAIN ROUTING ||=========================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // Admin Pages
        { path: '/admin/:modelName/view/:Id', element: <AdminViewPage /> },
        { path: '/admin/:modelName/list', element: <AdminListPage /> },
        { path: '/admin/:modelName/edit/:Id', element: <AdminCrudPage /> },
        { path: '/admin/:modelName/add', element: <AdminCrudPage /> },
        // Moderator Pages
        { path: '/page/:modelName/view/:Id', element: <AdminViewPage /> },
        { path: '/page/:modelName/list', element: <ListPage /> },
        { path: '/page/:modelName/edit/:Id', element: <AdminCrudPage /> },
        { path: '/page/:modelName/add', element: <AdminCrudPage /> },
    ]
};

export default AdminRoutes;
