import { useContext } from 'react';

// tenant provider
import TenantContext from 'contexts/TenantContext';

// ===========================|| TENANT HOOKS ||=========================== //

const useTenant = () => useContext(TenantContext);

export default useTenant;
