import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import Loader from 'ui-component/Loader';

import { CONFIG } from 'api/apiService';
// import Cookies from 'utils/Cookies';

export const getUser = () => {
    try {
        const userData = localStorage.getItem('user');
        const user = JSON.parse(userData);
        return user;
    } catch (err) {
        return null
    }
}

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    // console.log(' expire at ', (new Date(decoded.exp * 1000)).toISOString());
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken, user) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('user', JSON.stringify(user));
        if (user && user.company && user.company) {
            if (user.company._id)
                localStorage.setItem('tenant', JSON.stringify(user.company));
            else if (user.company.length && user.company[0]._id) {
                localStorage.setItem('tenant', JSON.stringify(user.company[0]));
            }
        }
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('tenant');
        localStorage.removeItem('user');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {},
    hasRole: (role) => {},
    hasAnyRole: (roles) => {},
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const hasAnyRole = (roles) => {
        const userData = getUser();
        let hasRole = false;

        if (userData && userData._id && userData.roles) {
            userData.roles.map(userRole => {
                if (roles.includes(userRole.code))
                    hasRole = true;
            });
        }

        return hasRole;
    };

    const hasRole = (role) => {
        const userData = getUser();
        let hasRole = false;

        if (userData && userData._id && userData.roles) {
            userData.roles.map(userRole => {
                if (userRole.code === role)
                    hasRole = true;
            });
        }

        return hasRole;
    };

    const login = async (email, password) => {
        const response = await axios.post(CONFIG.url + '/apiv3/auth/login', { email, password });
        const { token, user } = response.data;
        setSession(token, user);
        dispatch({
            type: LOGIN,
            payload: {
                user
            }
        });
    };

    const logout = async () => {
        const serviceToken = window.localStorage.getItem('serviceToken');
        setSession(null, null);
        dispatch({ type: LOGOUT });
        const userData = getUser();
        if (userData && userData._id) {
            const response = await axios.delete(CONFIG.url + '/api_me/auth/logout?userId=' + userData._id, {
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + serviceToken
                }
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const userData = getUser();
                // console.log(' jwt auth, verify: ', verifyToken(serviceToken), ' Id',(userData ? userData._id : ''));

                if (userData && userData._id) {
                    const response = await axios.get(CONFIG.url + '/api_me/user/info?userId=' + userData._id, {
                        headers:{
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + serviceToken
                        }
                    });
                    const user = response.data;
                    
                    if (serviceToken && 
                        // verifyToken(serviceToken) && 
                        user && user._id) {
                        setSession(serviceToken, user);
                        dispatch({
                            type: ACCOUNT_INITIALIZE,
                            payload: {
                                isLoggedIn: true,
                                user
                            }
                        });
                    } else {
                        dispatch({
                            type: ACCOUNT_INITIALIZE,
                            payload: {
                                isLoggedIn: false,
                                user: null
                            }
                        });
                        dispatch({ type: LOGOUT });
                    }
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout, hasRole, hasAnyRole }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
