import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandFacebook } from '@tabler/icons';

// ===========================|| NEWS WEBSITES PAGE & NEWS ARTICLE ITEMS ||=========================== //

const other = {
    id: 'magazine-roadmap',
    type: 'group',
    children: [
        // {
        //     id: 'socials',
        //     title: <FormattedMessage id="socials" />,
        //     type: 'item',
        //     url: '/magazines',
        //     icon: IconWallpaper,
        //     breadcrumbs: false
        // },
        {
            id: 'social_posts',
            title: <FormattedMessage id="social_posts" />,
            type: 'item',
            url: '/social_posts',
            icon: IconBrandFacebook,
            breadcrumbs: false
        }
    ],
};

export default other;
