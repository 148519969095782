// action - state management
import { TENANT_INITIALIZE, SET_TENANT } from './actions';

// ===========================|| TENANT REDUCER ||=========================== //

const tenantReducer = (state, action) => {
    switch (action.type) {
        case TENANT_INITIALIZE: {
            const { tenant } = action.payload;
            return {
                ...state,
                isInitialized: true,
                tenant
            };
        }
        case SET_TENANT: {
            const { tenant } = action.payload;
            return {
                ...state,
                tenant
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default tenantReducer;
