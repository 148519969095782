import dateFormat from 'dateformat'
import dayjs from "dayjs";
import moment from "moment";
import utc from 'dayjs/plugin/utc';
import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export async function generatePDF(elementToPrintId, callBack) {
  const element = document.getElementById(elementToPrintId);
  if (!element) {
    throw new Error(`Element with id ${elementToPrintId} not found`);
  }
  const canvas = await html2canvas(element, { scale: 1.5 });
  // const data = canvas.toDataURL("image/png");
  // const pdf = new jsPDF({
  //   orientation: "landscape",
  //   unit: "mm",
  //   // format: [129, 70],
  // });
  // const imgProperties = pdf.getImageProperties(data);
  // const pdfWidth = pdf.internal.pageSize.getWidth();
  // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  // pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  // pdf.save("print.pdf");

  const imgWidth = 129;
  const pageHeight = 70;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;
  heightLeft -= pageHeight;
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [129, 70],
    // margin: [72, 72, 72, 72],
    // html2canvas: {
    //   allowTaint: true,
    //   dpi: 300,
    //   letterRendering: true,
    //   logging: false,
    //   scale: 2
    // }
  });
  doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save('print.pdf', { returnPromise: true }).then(() => {
    // Code will be executed after save
    callBack && callBack()
  });
};

export function getPercentage(avg, total) {
  return Math.round(Number(avg) / Number(total) * 100);
}

export function datetimeFormat(datetime, format="yyyy-mm-dd HH:MM") {
    return dateFormat(datetime, format)
}

export function formatCurrency(curr) {
    return (curr).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function sortBalance(a, b){
    var balanceA = parseFloat(a.balance) + parseFloat(a.balanceInOrder);
    var balanceB = parseFloat(b.balance) + parseFloat(b.balanceInOrder);
    return balanceA < balanceB ? 1 : -1; // TODO: sort order
}

export function numberWithCommas(x) {
    return x ? x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

export function compareDate( a, b ) {
    if ( new Date(a.createdAt) < new Date(b.createdAt)){
        return -1;
    }
    if ( new Date(a.createdAt) > new Date(b.createdAt)){
        return 1;
    }
    return 0;
}

export function dayjsFormat(datetime) {
    dayjs.extend(utc)
    return dayjs.utc(datetime).format('YYYY-MM-DD HH:mm')
}

export function getPictureUploadUrl() {
  return process.env.REACT_APP_BACKEND_API + '/uploads/asset/'
}

export function timeSince(time) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
  
    var time_formats = [
      [60, 'секундын', 1], // 60
      [120, '1 минутын өмнө', '1 минутын дараа'], // 60*2
      [3600, 'минутын', 60], // 60*60, 60
      [7200, '1 цагийн өмнө', '1 цагийн дараа'], // 60*60*2
      [86400, 'цагийн', 3600], // 60*60*24, 60*60
      [172800, 'Өчигдөр', 'Маргааш'], // 60*60*24*2
      [604800, 'өдрийн', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Өнгөрсөн 7 хоног', 'Ирэх 7 хоног'], // 60*60*24*7*4*2
      [2419200, '7 хоногийн', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Өнгөрсөн сар', 'Ирэх сар'], // 60*60*24*7*4*2
      [29030400, 'сарын', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Өнгөрсөн жил', 'Ирэх жил'], // 60*60*24*7*4*12*2
      [2903040000, 'жилийн', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      //[5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      //[58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'өмнө',
      list_choice = 1;
  
    if (seconds == 0) {
      return 'дөнгөж сая'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = ' дараа ';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    
    return time;
}

export function getMonday() {
  let d = new Date();
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function getSunday() {
  const start = new Date();
  start.setDate(start.getDate() - start.getDay());
  const end = new Date(start);
  end.setDate(end.getDate() + 7);
  return end;
}

export function getToday(format = 'YYYY-MM-DD HH:mm') {
  let ts = Date.now();
  let today = new Date(ts);
  return moment(today).format(format);
}

export function getDay(day, format = 'YYYY-MM-DD HH:mm') {
  let ts = Date.now();
  let today = new Date(ts);
  today.setDate(today.getDate() + day);
  return moment(today).format(format);
}

export function highlightMatchingText(text, textToMatch) {
  if (!text || !textToMatch || !textToMatch.length)
    return text;
  const matchRegex = RegExp(textToMatch.join('|'), "ig");
  // Matches array needed to maintain the correct letter casing
  const matches = [...text.matchAll(matchRegex)];
  return text.split(matchRegex).map((nonBoldText, index, arr) => (
    <React.Fragment key={index}>
      {nonBoldText}
      {index + 1 !== arr.length && <mark>{matches[index]}</mark>}
    </React.Fragment>
  ));
}

export function hourTimeFormat(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `00:${pad(mm)}:${ss}`
}

export function pad(string) {
  return ('0' + string).slice(-2)
}