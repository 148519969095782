import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { JWTProvider } from 'contexts/JWTContext';
import { TenantProvider } from 'contexts/TenantContext';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll>
                        <JWTProvider>
                            <TenantProvider>
                                <Routes />
                                <Snackbar />
                            </TenantProvider>
                        </JWTProvider>
                    </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
