import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconNews, IconNotebook } from '@tabler/icons';

// constant

// ===========================|| NEWS WEBSITES PAGE & NEWS ARTICLE ITEMS ||=========================== //

const other = {
    id: 'newspaper-roadmap',
    type: 'group',
    children: [
        {
            id: 'newspapers',
            title: <FormattedMessage id="newspapers" />,
            type: 'item',
            url: '/newspapers',
            icon: IconNews,
            breadcrumbs: false
        },
        {
            id: 'newspaper_articles',
            title: <FormattedMessage id="newspaper_articles" />,
            type: 'item',
            url: '/newspaper_articles',
            icon: IconNotebook,
            breadcrumbs: false
        }
    ],
};

export default other;
