import other from './other';
import tv from './tv';
import dashboard from './dashboard';
// import widget from './widget';
import newspaper from './newspaper';
// import magazine from './magazine';
import social from './social';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [dashboard, other, tv, newspaper, social]
};

export default menuItems;
