import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardHome = Loadable(lazy(() => import('views/dashboard/Home')));
const DashboardReports = Loadable(lazy(() => import('views/dashboard/Reports')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

// news websites page routing
const NewsWebsitePage = Loadable(lazy(() => import('views/news_websites')));
const NewsArticlePage = Loadable(lazy(() => import('views/news_articles')));
const NewsLinkPage = Loadable(lazy(() => import('views/news_articles/links')));
const AddSiteArticlePage = Loadable(lazy(() => import('views/news_articles/Add')));
const TVsPage = Loadable(lazy(() => import('views/tvs')));
const TVProgramsPage = Loadable(lazy(() => import('views/tvPrograms')));
const TVCalendarPage = Loadable(lazy(() => import('views/tvCalendar')));
const AddTvProgramMarkPage = Loadable(lazy(() => import('views/tvPrograms/Add')));
const KeywordsPage = Loadable(lazy(() => import('views/keywords')));
const NewspaperPage = Loadable(lazy(() => import('views/newspapers')));
const NewspaperArticlePage = Loadable(lazy(() => import('views/newspaper_articles')));
const AddNewspaperArticlePage = Loadable(lazy(() => import('views/newspaper_articles/Add')));
const MagazinePage = Loadable(lazy(() => import('views/magazines')));
const MagazineArticlePage = Loadable(lazy(() => import('views/magazine_articles')));
const AddMagazineArticlePage = Loadable(lazy(() => import('views/magazine_articles/Add')));
const SocialPostPage = Loadable(lazy(() => import('views/socialPosts')));
const AddSocialPostPage = Loadable(lazy(() => import('views/socialPosts/Add')));

const AppUserAccountProfile = Loadable(lazy(() => import('views/application/users/account-profile/Profile3')));

// widget routing
const WidgetChart = Loadable(lazy(() => import('views/widget/Chart')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardHome />
        },
        {
            path: '/reports',
            element: <DashboardReports />
        },
        {
            path: '/analytics',
            element: <DashboardAnalytics />
        },

        {
            path: '/widget/chart',
            element: <WidgetChart />
        },
        {
            path: '/news_websites',
            element: <NewsWebsitePage />
        },
        {
            path: '/news_articles/:search',
            element: <NewsArticlePage />
        },
        {
            path: '/news_articles',
            element: <NewsArticlePage />
        },
        {
            path: '/news_links',
            element: <NewsLinkPage />
        },
        {
            path: '/news_article/add',
            element: <AddSiteArticlePage />
        },
        {
            path: '/tv_list',
            element: <TVsPage />
        },
        {
            path: '/tv_programs',
            element: <TVProgramsPage />
        },
        {
            path: '/tv_archives',
            element: <TVCalendarPage />
        },
        {
            path: '/tvProgramMark/add',
            element: <AddTvProgramMarkPage />
        },
        {
            path: '/keywords',
            element: <KeywordsPage />
        },
        {
            path: '/newspapers',
            element: <NewspaperPage />
        },
        {
            path: '/newspaper_articles',
            element: <NewspaperArticlePage />
        },
        {
            path: '/newspaper_article/add',
            element: <AddNewspaperArticlePage />
        },
        {
            path: '/magazines',
            element: <MagazinePage />
        },
        {
            path: '/magazine_articles',
            element: <MagazineArticlePage />
        },
        {
            path: '/magazine_article/add',
            element: <AddMagazineArticlePage />
        },
        {
            path: '/social_posts',
            element: <SocialPostPage />
        },
        {
            path: '/social_post/add',
            element: <AddSocialPostPage />
        },
        {
            path: '/account-settings',
            element: <AppUserAccountProfile />
        },
    ]
};

export default MainRoutes;
