const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 5,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'mn',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '3 days'
    },
    pageLimit: 100
};

export default config;
