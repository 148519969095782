import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { TENANT_INITIALIZE, SET_TENANT } from 'store/actions';
import tenantReducer from 'store/tenantReducer';

// project imports
import Loader from 'ui-component/Loader';

// constant
const initialState = {
    isInitialized: false,
    tenant: null
};

const TenantContext = createContext({
    ...initialState,
    getTenant: () => {},
    setTenant: (tenant) => {},
});

export const TenantProvider = ({ children }) => {
    const [state, dispatch] = useReducer(tenantReducer, initialState);

    const getTenant = () => {
        const tenantData = window.localStorage.getItem('tenant');
        return JSON.parse(tenantData);
    };

    const setTenant = (tenant) => {
        window.localStorage.setItem('tenant', JSON.stringify(tenant));
        dispatch({
            type: SET_TENANT,
            payload: {
                tenant
            }
        });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const tenant = JSON.parse(window.localStorage.getItem('tenant'));
                if (tenant) {
                    // console.log(' setTenant', JSON.stringify(tenant));
                    dispatch({
                        type: SET_TENANT,
                        payload: {
                            tenant
                        }
                    });
                }

                dispatch({
                    type: TENANT_INITIALIZE,
                    payload: {
                        isInitialized: true,
                        tenant
                    }
                });
            } catch (err) {
                console.error('Tenant Init Error', err);
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <TenantContext.Provider value={{ ...state, getTenant, setTenant }}>{children}</TenantContext.Provider>;
};

TenantProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default TenantContext;