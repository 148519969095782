import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconDeviceTv, IconDeviceTvOld, IconDeviceTvOff } from '@tabler/icons';

// constant
const icons = { IconBrandChrome };

// ===========================|| NEWS WEBSITES PAGE & NEWS ARTICLE ITEMS ||=========================== //

const other = {
    id: 'tvs-roadmap',
    type: 'group',
    children: [
        {
            id: 'tvs',
            title: <FormattedMessage id="tvs" />,
            type: 'item',
            url: '/tv_list',
            icon: IconDeviceTvOld,
            breadcrumbs: false
        },
        {
            id: 'tvPrograms',
            title: <FormattedMessage id="tvPrograms" />,
            type: 'item',
            url: '/tv_programs',
            icon: IconDeviceTv,
            breadcrumbs: false
        },
        {
            id: 'tvArchives',
            title: <FormattedMessage id="tvArchives" />,
            type: 'item',
            url: '/tv_archives',
            icon: IconDeviceTvOff,
            breadcrumbs: false
        }
    ],
};

export default other;
